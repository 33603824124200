import React, { useState, useRef } from "react";
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';

import { Row, Col, Container, Modal } from "react-bootstrap"

import ReactPlayer from 'react-player'
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
  } from "react-device-detect";

const PlayVideo = (props)  => {
    const [play, setPlayvideo] = useState(true);
    const [mute, setMute] = useState(true);
    const vidRef = useRef(null);

    //console.log("propssss",props)

    var video_url = "https://www.youtube-nocookie.com/embed/"+props.videoId

    if(video_url.includes('ggfx')){
        video_url = props.videoId;
    }
    return (
    //     <React.Fragment>
    //      <ModalVideo 
    //         channel='youtube' 
    //         autoplay 
    //         isOpen={props.isOpen} 
    //         videoId={props.videoId}
    //         onClose={ e => props.isCloseFunction(false)}
    //         allowFullScreen={false}
    //      />
    // {
    //     //console.log("===url==",props.url_id)
    // }
    //     </React.Fragment>
        
     <div className="animated">
        <Modal
            show={props.isOpen}
            onHide={e => props.isCloseFunction(false)}
            dialogClassName="modal-video"
            aria-labelledby="example-custom-modal-styling-title"
            backdropClassName="video-backdrop"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <ReactPlayer autoplay ref={vidRef} url={video_url} modestbranding="1" controls={false} autoplay={true} playsinline={true} loop={true} playing={play}  width='100%' height='100%' />
            </Modal.Body>
        </Modal>
     </div>
    )
};

export default PlayVideo;